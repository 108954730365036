import { useEffect } from 'react'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import PageWrapper from 'components/PageWrapper'
import styles from './login.module.scss'
import { useLocalize, AttachLocalizedList } from 'localize'
import locJson from './login.loc.json'
import { defaultTestUrl } from 'config'

AttachLocalizedList(locJson)

const Index: NextPage = () => {
  const { Localize, languageCode } = useLocalize()
  const router = useRouter()

  useEffect(() => {
    if (window?.location) {
      if (window.location.hostname === 'learn.test.hwapps.net') {
        window.location.href = defaultTestUrl
      } else {
        router.replace('/logout', undefined, { locale: languageCode })
      }
    }
  }, [window?.location])


  return (
    <PageWrapper hideSkipLink>
      <main className={styles.main} tabIndex={-1} id='main'>
        {Localize('IndexNothingToSeeHere')}
      </main>
    </PageWrapper>
  )
}

export default Index
